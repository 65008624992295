<template>
  <h3>{{header}}</h3>
  <table>
    <thead>
      <tr>
        <th v-if="type != 'pendingAdmin'">Nombre</th>
        <th>Correo</th>
        <th>Opciones</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="user in userList" :key="user.id">
        <td v-if="type != 'pendingAdmin'">{{user.nomUsu}} {{user.apePatUsu}} {{user.apeMatUsu}}</td>
        <td>{{user.corrUsu}}</td>
        <td>
          <router-link :to="{ name: 'EditProfileId', params: {id: user.idUsu} }" class="">Editar</router-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>


<script>
  export default {
    name: "UserList",
    props:{
      header: String,
      userList: Array,
      type: String
    },
    data() {
      return {
      }
    },
    methods: {
    }
  }
</script>