<template>
  <div :class="['form__group', ]">
    <label class="form__label " :for="$.uid">
      <slot></slot>
    </label>
    <select v-if="type === 'select'" class="form__input" v-model="inputValue" :id="$.uid" :required="required" :autofocus="autofocus" :disabled="disabled">
      <option v-if="selectPrompt" value="" :selected="true" :disabled="required">{{selectPrompt}}</option>
      <option v-for="option in options" :key="option.id" :value="option.id">{{option.name}}</option>
    </select>
    <div v-else-if="type === 'radioGroup'" class="checkbox__group">
      <template v-for="(option, index) in groupOptions" :key="index">
        <input :id="`${$.uid}-${index}`" type="radio" :value="option" v-model="inputValue" :required="required">
        <label class="checkbox__label" :for="`${$.uid}-${index}`">
          <div v-if="option.icon" class="checkbox__indicator">
            <img referrerpolicy="no-referrer" :src="option.icon">
          </div>
          <div class="checkbox__box">
            <p class="caption--xs">{{option}}</p>
          </div>
        </label>
      </template>
    </div>
    <textarea v-else-if="type === 'textarea'" class="form__input" v-model="inputValue" :id="$.uid" :required="required" :autofocus="autofocus" :disabled="disabled" :readonly="readonly"></textarea>
    <input v-else-if="type === 'date'" class="form__input" type="date" v-model="inputValue" :id="$.uid" :required="required" :autofocus="autofocus" :disabled="disabled" :placeholder="placeholder" :min="min" :max="max">
    <slot v-else-if="type === 'custom'" name="customInput" :id="$.uid" :required="required"></slot>
    <input v-else-if="type === 'number'" class="form__input" type="number" v-model="inputValue" :id="$.uid" :required="required" :autofocus="autofocus" :disabled="disabled" :placeholder="placeholder" :readonly="readonly" :min="min" :max="max" :step="step">
    <input v-else-if="type === 'file'" class="" type="file" :required="required" :accept="accept"/>
    <input v-else-if="type === 'file-aws'" class="" type="file" :id="$.uid" :required="required" ref="{{ref}}" :multiple="multiple" :accept="accept">
    <input v-else-if="type === 'color'" class="" type="color" :id="$.uid" :required="required" ref="{{ref}}">
    <input v-else class="form__input" :type="type" v-model="inputValue" :id="$.uid" :required="required" :autofocus="autofocus" :disabled="disabled" :placeholder="placeholder" :readonly="readonly" :minlength="minlength" :maxlength="maxlength" :step="step" ref="{{ref}}">

    <span v-if="info" :class="['form__input-info', infotype == 'error' ? 'form__input-info--error' : '']">{{ info }}</span>
  </div>
</template>

<script>

export default {
  name: 'FormInput',
  props: [
    'modelValue',
    'type',
    'options', // : { type: Array, default () { return [] } } },
    'required', // : { type: Boolean, default () { return false } } },
    'autofocus', // : { type: Boolean, default () { return false } } },
    'disabled', // : { type: Boolean, default () { return false } } }
    'placeholder',
    'selectPrompt',
    'min',
    'max',
    'groupOptions',
    'minlength',
    'maxlength',
    'readonly',
    'ref',
    'step',
    'info',
    'infotype',
    'multiple',
    'accept'
  ],
  emits: ['update:modelValue'],
  computed: {
    inputValue: {
      get () { return this.modelValue },
      set (val) { this.$emit('update:modelValue', val) }
    }
  }
}
</script>
