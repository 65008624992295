<template>
  <a href="#" class="btn" @click.prevent="showInviteModal = true">Invitar</a>

  <user-list :header="'Administradores'" :type="'activeAdmin'" :userList="users.filter(n => n.nomUsu)"/>
  <user-list :header="'Invitaciones pendientes'" :type="'pendingAdmin'" :userList="users.filter(n => !n.nomUsu)"/>

  <modal-dialog v-if="showInviteModal" @close="showInviteModal = false">
    <template #title>Invitar Administrador</template>
    <template #subtitle>Ingresa el correo electrónico al cual deseas enviar la invitación</template>
    <template #content>
      <div>
        <label>Correo electrónico</label>
        <form-input v-model="inviteMail"/>
      </div>
    </template>
    <template #footer>
      <a class="btn" @click.prevent="showInviteModal = false">Cancelar</a>
      <button type="button" :disabled="!isValidEmail(inviteMail) || sending" class="btn" @click.prevent="inviteAdmin">{{sending ? 'Enviando...' : 'Invitar'}}</button>
    </template>
  </modal-dialog>
</template>

<script>
  import UserList from '@/components/UserList'
  import FormInput from '@/components/FormInput'
  import ModalDialog from '@/components/ModalDialog'
  export default {
    name: 'Index',
    components: { UserList, FormInput, ModalDialog },
    data() {
      return {
        users: [],
        showInviteModal: false,
        inviteMail: '',
        sending: false
      }
    },
    async created() {
      await this.getUsers();
    },
    methods:{
      async getUsers() {
        this.users = (await this.$axios.post('Users/admins')).data;
      },
      isValidEmail(email){
        var validator = require("email-validator");
        return validator.validate(email);
      },
      async inviteAdmin(){
        let res = (await this.$axios.post(`Users/checkEmail`,{
          email: this.inviteMail
        })).data
        if(res){
          this.$toast.error('El correo utilizado ya está en uso')
        }
        else{
          this.sending = true
          if(this.isValidEmail(this.inviteMail)){
            res = (await this.$axios.post('Users/admin/invite', {
              toMail: this.inviteMail,
              jwt: this.$cookies.get('jwtA')
            })).data
            if(res){
              this.$toast.success('La invitación ha sido enviada exitosamente')
              this.showInviteModal = false
              this.inviteMail = ''
              this.getUsers()
            }
            else
              this.$toast.error('Ocurrió un error al invitar al usuario')
          }
          this.sending = false
        }
      }
    }
  }
</script>
